import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ProjectHelse = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="Mantenimiento y Hosting web Drupal" description="Ofrecemos a nuestros clientes tanto el alojamiento en nuestros propios servidores como las tareas de mantenimiento y actualización periódicas del CMS." />
  <section className="jumbotron bg-transparent pb-6" id="header">
    <div className="container">
      <div className="row align-items-center text-center">
        <h1 className="font-weight-bold display-4 pl-0 p-1 col-md-9 offset-md-2 col-sm-12">Mantenimiento y Hosting</h1>
        <p className="lead mb-4 col-md-9 offset-md-2 col-sm-12">Cuando finalizamos un proyecto en Drupal ofrecemos a nuestros clientes tanto el alojamiento en nuestros propios servidores como las tareas propias de mantenimiento y actualización periódicas del CMS.</p>
      </div>
    </div>
  </section>
  <section className=" pb-5 pt-5 bg-light text-left" id="features">
    <div className="container">
      <div className="row text-left">
        <div className="col col-md-9 offset-md-2 col-sm-12">
          <h2>
            <strong className="text-left">Mantenimiento Drupal</strong>
          </h2>
          <p className="lead pt-4">Drupal es de software libre, por lo que requiere una actualización constante (al menos una vez al mes), para aplicar las actualizaciones de mantenimiento correctivo y preventivo necesarias para asegurar que el sitio está funcionando de manera óptima y evitar fallos de seguridad.</p>
          <p className="lead">Disponemos de dos modalidades de mantenimiento.</p>
          <h3 className="pt-4">Mantenimiento mensual de Drupal</h3>
          <p className="lead">Incluye las actualizaciones mensuales del Core y los módulos contribuidos de Drupal, tanto cuando el servidor es nuestro como cuando está alojado en la infraestructura del cliente.</p>
          <h3 className="pt-2">Mantenimiento puntual</h3>
          <p className="lead pb-5">Cuando salen actualizaciones de seguridad, hacemos una actualización one-time de los módulos desactualizados.</p>
        </div>
      </div>
      <div className="row text-left">
        <div className="col col-md-9 offset-md-2 col-sm-12">
          <h2>
            <strong className="text-left pt-0">Hosting Drupal</strong>
          </h2>
          <p className="lead pt-4">Para garantizar unos niveles de servicio óptimos y proporcionar a nuestros clientes una rápida respuesta ante posibles incidencias en el servicio, recomendamos implantar el proyecto web en nuestros propios servidores VPS.</p>
          <p className="lead">Notaréis bastante la diferencia en la rapidez de carga con respecto a los hostings compartidos, y dispondréis de un servidor optimizado y monitorizado.</p>
        </div>
      </div>
    </div>
  </section>

    </Layout>
  )
}

export default ProjectHelse
